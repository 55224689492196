.product-filter {
    margin: 30px auto;
  }
  .product-filter nav {
    display: flex;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
  }
  .product-filter p {
    font-size: 0.9em;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    .product-filter nav {
      flex-direction: column;
    }

  }