.advanced-filter {
    padding: 0!important;
    overflow: hidden;
    max-height: 0;
    transition: padding 0.1s ease-out, max-height 0.3s ease-out;
  }
  
  .advanced-filter.open {
    max-height: 500px; /* or any other height you need */
    overflow:visible;
    padding: 10px!important;
    transition: padding 0.1s ease-out, max-height 0.3s ease-out;
  }

  .advanced-filter label{
    margin:10px;
  }
