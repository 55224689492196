.product-details {
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: start;
  grid-gap: 60px;
}

/* product summary */
.product-summary {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  overflow-y: scroll;
  height: 80vh;
}

@media screen and (max-width: 768px) {
  .product-summary {
    overflow-y: auto;
    height: auto;
  }

}

.product-summary .due-date {
  margin: 10px 0;
  font-size: 0.9em;
  color: var(--title-color);
}

.product-summary .details {
  margin: 30px 0;
  color: var(--text-color);
  line-height: 1.8em;
  font-size: 0.9em;
  max-width: 600px;
  overflow-wrap: break-word;
}

.product-summary h4 {
  color: var(--text-color);
  font-size: 0.9em;
}

.product-summary .assigned-users {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
}

.product-summary .assigned-users .avatar {
  margin-right: 10px;
}

.product-summary+.btn {
  margin-top: 20px;
}

/* product comments */
.product-comments label {
  margin-bottom: 0px;
}

.product-comments textarea {
  min-height: 40px;
  font-size: 1.5em;
}

/* comment list */
.product-comments h4 {
  color: var(--heading-color);
}

.product-comments li {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  margin-top: 20px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  background: #fff;
}

.comment-author {
  display: flex;
  align-items: center;
  color: var(--title-color);
}

.comment-author .avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.comment-date {
  color: var(--text-color);
  font-size: 0.9em;
  margin: 4px 0 10px;
}

.comment-content {
  color: var(--text-color);
  font-size: 0.9em;
}

@media screen and (max-width: 768px) {
  .product-details {
    display: flex;
    padding: 10px;
  }

  .product-summary .assigned-users {
    padding: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}