.sidebar {
  width: 300px;
  min-width: 300px;
  background: var(--primary-color);
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  transition: all 0.3s ease;
  color: #fff;
}

/*Transitions to make it collapsible*/
.sidebar.open {
  transform: translateX(0);
}
.sidebar.closed {
  transform: translateX(-230px);
}


.sidebar button {
  display: block;
  margin: 10px;
}


.sidebar-content {
  position: relative;
  width: inherit;
  transition: all 0.3s ease;
}

.sidebar .user {
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  padding: 40px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.sidebar .links {
  margin-top: 80px;
  margin-left: 20px;
}

.sidebar .links li {
  margin-top: 10px;
}

.sidebar .links a {
  display: flex;
  padding: 10px;
  text-decoration: none;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
}

.sidebar .links img {
  margin-right: 10px;
  filter: invert(100%);
}

.sidebar .links a.active {
  color: #555;
  background: var(--bg-color);
  border-radius: 20px 0 0 20px;
}

.sidebar .links .active img {
  filter: invert(40%);
}

.button-holder {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 1;
}

@media (max-width: 768px) {
  .sidebar {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
  }
}