.App {
  display: flex;
  width: 100vw;
}

.App .container {
  flex-grow: 1;
  padding: 0 60px;
  
}
.landing-container {
  padding: 0;
}
.mobile {
  margin:0 !important;
  width: 100vw;
}

@media print {
  .no-print {
    display: none;
  }
}
.parent-div {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.parent-div div:nth-child(2) {
  margin-left: 20px;
}

@media (max-width: 768px) {
  .App {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .App .container {
    padding: 0;
    width: 100vw;
  }
  .parent-div {
    padding: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
