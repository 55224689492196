.product-table {
  
    padding: 10px;
    overflow-y: scroll;
    height: 500px;
    width:100%;
}
a{
    text-decoration: none!important;
}
button a{
    color: inherit;
}
.button-download-holder {
    display: flex;
    justify-content: flex-end;
    top:0;

  }

@media (max-width: 768px) {
    .product-table {
        font-size: 14px;
        margin-top: 0;
        overflow-y: auto;
        height: auto;
        padding: 0px;

    }
}