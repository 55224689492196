.product-list {
  margin-top: 40px;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
}

.product-list a {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}

.product-list h4 {
  font-size: 0.9em;
  color: var(--heading-color);
}

.product-list p {
  color: var(--text-color);
  font-size: 0.9em;
}

.product-list .assigned-to {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.product-list ul {
  margin: 10px 0;
  display: flex;
}

.product-list li {
  margin-right: 10px;
}

.product-list .avatar {
  width: 30px;
  height: 30px;
}

@media (max-width: 768px) {
  .product-list {
    grid-template-columns: 1fr;
    height: auto;
    font-size: 14px;
    padding: 20px;
    overflow-y: auto;

  }
}