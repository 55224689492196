@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* variables */
:root {
  --heading-color: #444;
  --text-color: #999;
  --primary-color: #14213d;
  --secondary-color: #0066ff;
  --highlight-color: #fca311;
  --details-color: #ff006e;
  --bg-color: #f4f4f4;
}

/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
  background: var(--bg-color);
}

ul,
li,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

/* layout */
.page-title {
  font-size: 1em;
  color: var(--heading-color);
  display: inline-block;
}

.btn {
  background: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
}

.btn:hover {
  color: #fff;
  background-color: var(--primary-color);
}

/* forms */
label {
  display: block;
  margin: 24px auto;
}

label span {
  display: block;
  margin-bottom: 6px;
}

a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}

input,
textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}

textarea {
  min-height: 160px;
}

.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

/*scrollbar*/
::-webkit-scrollbar {
  width: 8px;
  margin-left: 20px;
}

::-webkit-scrollbar-track {
  /*background: linear-gradient(0deg, var(--secondary-color) 0%, var(--primary-color) 100%);*/
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #dfe3ee;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--highlight-color);
}

/*tags*/
/*tags*/
.div-tag {
  padding: 5px;
  border-radius: 10px;
  font-weight: 600;
  margin-right: 10px;
  margin-bottom: 5px;
}

.div-tag {
  display: inline-block;
  background-color: var(--highlight-color);
  color: #000;
}

/*tables*/
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 30vw;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  overflow-x: scroll;
}

.styled-table thead tr {
  background-color: var(--primary-color);
  color: #ffffff;
  text-align: left;
  font-weight: 500;
}
.styled-table th {
  font-weight: 500;
}

.styled-table th,
.styled-table td {
  padding: 15px 15px;
  text-align: left;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

table tr:nth-child(even) {
  background-color: #eeeeee;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #d9d9d9;
}


@media screen and (max-width: 768px) {
  .MuiBottomNavigationAction-root.Mui-selected {
    color: rgba(255, 255, 255, 1) !important;
  }
  .styled-table {
    max-width: 100%;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    overflow-x: scroll;
    table-layout: fixed;
  }
  .styled-table th,
  .styled-table td {
    padding: 5px 2px;
  }

}
