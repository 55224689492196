.bold-header {
  font-weight: bold !important;
}

.MuiDataGrid-columnHeader,
.MuiDataGrid-colCellTitle,
.MuiDataGrid-toolbarContainer {
  background-color: var(--primary-color);
  color: white;
  font-family: Poppins, sans-serif;
  font-size: .8em;
}

.datagrid .MuiSvgIcon-root {
  color: white !important;
  background-color: var(--primary-color);
}

.datagrid .MuiButtonBase-root {
  color: inherit;
}

.MuiListItemText-root {
  color: grey;
}

.MuiFormLabel-root {
  opacity: 0 !important;
  font-family: inherit !important;
}