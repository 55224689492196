.create-form-shipping {
    max-width: 600px;
    padding:10px;
  }
  .product-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  
  .div-left {
    width: 80%;
    margin-right: 20px;
  }